<template>
    <div class="quizpage-wrapper">
        <base-toast :settings="toast"></base-toast>

        <mail-wrapper :template="template"></mail-wrapper>

        <div v-if="showContinueButton">
            <div
                class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    button-wrapper
                "
            >
                <base-button
                    type="button"
                    @clicked="next()"
                    size="lg"
                    class="quiz-button"
                    data-test="continue"
                >
                    {{ $et("quiz_component", "continue") }}
                </base-button>
            </div>
        </div>

        <div v-else>
            <div class="button-wrapper">
                <base-button
                    size="lg"
                    class="quiz-button"
                    type="button"
                    variant="success"
                    id="no"
                    data-test="no"
                    @clicked="beforeNext(false)"
                >
                    {{ $et("quiz_component", "btn_no") }}
                </base-button>
                <base-button
                    size="lg"
                    class="quiz-button"
                    type="button"
                    variant="primary"
                    id="yes"
                    data-test="yes"
                    @clicked="beforeNext(true)"
                >
                    {{ $et("quiz_component", "btn_yes") }}
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MailWrapper from "@/components/MailWrapper";
import tippy, { roundArrow, sticky } from "tippy.js";
import "tippy.js/dist/svg-arrow.css";
export default {
    name: "Quizpage",
    components: { MailWrapper },
    data() {
        return {
            toast: {},
            showContinueButton: false,
            tooltips: [],
        };
    },
    computed: {
        ...mapGetters("quiz", ["templates"]),
        ...mapGetters("settings", ["Language"]),

        currentTemplateIndex: {
            get() {
                return this.$store.getters["quiz/currentTemplateIndex"];
            },
            set(v) {
                return this.$store.dispatch("quiz/setCurrentTemplateIndex", v);
            },
        },

        template() {
            return this.templates[this.currentTemplateIndex];
        },
    },

    async beforeMount() {
        await this.$store.dispatch("quiz/fetchTemplates", this.Language);
    },

    async mounted() {
        this.$store.dispatch("session/startSession");
        this.currentTemplateIndex = 0;
    },

    methods: {
        ...mapActions("session", ["setAnswers"]),

        beforeNext(answer) {
            // set user answer
            this.templates[this.currentTemplateIndex].answer = answer;
            this.validateAnswer(answer);
            this.showIndicators();
            this.showContinueButton = true;
        },

        async next() {
            this.showContinueButton = false;
            await this.removeIndicators();

            // if currentTemplateIndex question is last redirect to result page
            if (this.currentTemplateIndex == this.templates.length - 1) {
                this.redirectToResultPage();
                return;
            }

            // move to first question if currentTemplateIndex is greater than existing templates
            // length else move to next question
            if (this.currentTemplateIndex >= this.templates.length - 1) {
                this.currentTemplateIndex = 0;
            } else {
                this.currentTemplateIndex++;
            }
        },

        removeIndicators() {
            return new Promise((resolve) => {
                const refs = [
                    ...document.querySelectorAll(`[data-tippy-indicator]`),
                ];
                refs.forEach((ref) => {
                    ref.removeAttribute("data-tippy-indicator");
                });

                this.tooltips.forEach((tt) => {
                    tt.destroy();
                });
                resolve();
            });
        },

        showIndicators() {
            this.tooltips = tippy("[data-tippy-indicator]", {
                content: (reference) =>
                    reference.getAttribute("data-tippy-indicator"),
                // appendTo: (reference) => reference,
                showOnCreate: true,
                trigger: "manual",
                theme: "l8",
                arrow: roundArrow,
                zIndex: 1,
                hideOnClick: false,
                // interactive: true,
                plugins: [sticky],
            });
        },

        //redirect to result page.
        redirectToResultPage() {
            this.setAnswers(this.templates);
            this.$router.push({ path: "/result" });
        },

        // Validate and notify the answer.
        validateAnswer() {
            if (this.template.isPhishing == this.template.answer) {
                this.toast = {
                    message: this.$et("quiz_component", "correct_msg"),
                    variant: "success",
                    delay: 5000,
                };
                return true;
            } else {
                this.toast = {
                    message: this.$et("quiz_component", "wrong_msg"),
                    variant: "danger",
                    delay: 5000,
                };
                return false;
            }
        },
    },
};
</script>
<style scoped>
.quizpage-wrapper {
    overflow: auto;
    position: relative;
}
.button-wrapper {
    z-index: 9999;
    background: rgba(0, 0, 0, 0.2);
    gap: 0.5em;
    position: fixed !important;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1em 0.5em;
    left: 0;
    right: 0;
    bottom: 0;
}
.quiz-button {
    width: 50%;
    height: 4rem;
}
@media screen and (min-width: 576px) {
    .quiz-button {
        width: 45%;
        height: 4.5rem;
    }
}
@media screen and (min-width: 768px) {
    .quiz-button {
        width: 40%;
    }
}
@media screen and (min-width: 1200px) {
    .quiz-button {
        width: 25%;
    }
}
</style>
