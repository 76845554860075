<template>
  <div id="mailwrapper">
    <div class="mailcard mb-xl-0">
      <b-progress-circular
        :diameter="60"
        :total-steps="templates.length"
        :completedSteps="currentTemplateIndex + 1"
      >
        <small class="fw-bold" style="font-size: 0.8rem" data-test="steps">{{
          currentTemplateIndex + 1 + "/" + templates.length
        }}</small>
      </b-progress-circular>
      <div id="mailheader" data-tippy-placement="top">
        <div>
          <div class="row no-gutters justify-content-between">
            <h4>
              <span>{{ template?.subject }}</span>
            </h4>
          </div>
        </div>
        <div class="mailinfo">
          <div class="avatar">
            <i class="bi bi-person-circle"></i>
          </div>
          <div class="align-self-center w-100">
            <div class="d-flex d-xl-block justify-content-between">
                            <span :data-tippy-info="template?.sender?.address" class="clickable">
                {{ template?.sender?.name }}
              </span>

                            <base-icon
                                class="bi bi-caret-down-fill"
                            ></base-icon>
            </div>
            <div
              id="mailheader_user"
                            class="d-flex d-xl-block justify-content-between "
            >
              <span :data-tippy-info="user?.Email" class="clickable">
                {{ user?.Name }}
              </span>
                            <base-icon
                                class="bi bi-caret-down-fill"
                            ></base-icon>
            </div>
          </div>
        </div>
      </div>
      <div id="mailcontent" v-html="template?.markup"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import tippy, { roundArrow } from "tippy.js";
import "tippy.js/themes/material.css";

import BProgressCircular from "./ui/BProgressCircular.vue";

export default {
  name: "MailWrapper",
  components: { BProgressCircular },

  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      extendedMailHeader: false,
      tooltips: [],
    };
  },
  methods: {
    /**
     * this function is temporary - the placeholders should be removed by the API in the future
     */
    replacePlaceholders() {
      const replacements = {
        "{%salutation%}":
          this.user.Gender === "other"
            ? ""
            : this.$et("salutation", this.$h(this.user.Gender)),
        "{%name%}": this.$h(this.user.Name),
        "{%email%}": this.$h(this.user.Email),
        "{%days_2%}": this.$moment().add(2, "days").format("ll"),
        "{%days_4%}": this.$moment().add(4, "days").format("ll"),
        "{%days_7%}": this.$moment().add(7, "days").format("dddd, L"),
        "{%date%}": this.$moment().format("YYYY"),
        "{%today%}": this.$moment().format("DD.MM.YY"),
                "{%startOfMonth%}": this.$moment()
                    .startOf("month")
                    .format("DD.MM.YY"),
        "{%endOfNextMonth%}": this.$moment()
          .add(1, "months")
          .endOf("month")
          .format("DD.MM.YY"),
        "{%link%}": "javascript:void(0);",
      };

      this.template.markup = this.template.markup.replace(
        new RegExp(Object.keys(replacements).join("|"), "gi"),
        function (matched) {
          return replacements[matched];
        }
      );
    },

    replaceHyperlinks(href) {
      const mailContainer = document.getElementById("mailcontent");

            const links = [].slice.apply(
                mailContainer.getElementsByTagName("a")
            );
      links.forEach((link) => {
        link.href = href;
      });
    },

    setIndicators() {
      let indicators = Object.keys(this.template.indicators);

      return new Promise((resolve) => {
        resolve(
          this.$nextTick(() => {
            indicators.forEach((key) => {
              let el = document.getElementById(key);
              el.setAttribute(
                "data-tippy-indicator",
                this.template.indicators[key]
              );
            });
          })
        );
      });
    },

    setInfo() {
      return new Promise((resolve) => {
        resolve(
          (this.tooltips = tippy("[data-tippy-info]", {
            content: (reference) => {
              return reference.getAttribute("data-tippy-info");
            },
            trigger: "click",
            arrow: roundArrow,
            theme: "material",
            zIndex: 1,
          }))
        );
      });
    },

    removeTippyInstances() {
      return new Promise((resolve) => {
        this.tooltips.forEach((tt) => {
          tt.destroy();
        });
        resolve();
      });
    },

    async initTemplate() {
      if (this.template.markup) {
        this.replacePlaceholders();
        this.replaceHyperlinks("javascript:void(0);");
        await this.setIndicators();
        await this.setInfo();
      }
    },
  },

  computed: {
    ...mapGetters("session", ["user"]),
    ...mapGetters("quiz", ["templates", "currentTemplateIndex"]),
  },

  watch: {
    template: {
      immediate: true,
      async handler() {
        await this.removeTippyInstances();
        this.initTemplate();
      },
    },
  },
};
</script>
<style scoped>
#mailwrapper {
  min-width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 6.5rem;
  padding-top: 2em;
}
.mailcard {
  /* background: var(--bs-gray-100); */
  box-shadow: 0 0 1em var(--bs-gray-200);
  border-radius: 0.5em;
  width: 100vw;
  max-width: 100%;
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid var(--bs-border-color);
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .mailcard {
    width: 50vw;
  }
}
.mailinfo {
  display: flex;
  width: 100%;
  gap: 1em;
  word-wrap: break-word;
  word-break: break-word;
}
#mailheader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
    padding: 1.5em 1em 0.25em 1em;
  border-bottom: 1px solid var(--bs-border-color);
}

#mailcontent {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: auto;
  display: flex;
  padding: 1em;
}

.avatar {
  align-self: center;
  font-size: 3em;
}
</style>
